import React from 'react';
import { graphql } from 'gatsby';

import { SusPageProps } from '@sus-core/hooks/page/SusPageProps';
import { SubCategoryGrid } from '@sus-core/components/category/SubCategoryGrid';
import { PageProvider } from '@sus-core/hooks/page/PageContext';
import Layout from '@sus-core/components/scaffolding/Layout';

type LandingPageProps = SusPageProps<GatsbyTypes.LandingPageQuery>;

export default function LandingPage({
  data,
  location,
  pageContext,
}: LandingPageProps) {
  const subCategoryData = data.allMagentoCategory?.edges.map(a => a.node);

  const {
    title,
    meta_title,
    meta_description,
    meta_keywords,
    content_heading,
    content,
  } = data.sus.cmsPage;

  return (
    <PageProvider
      data={{
        pageType: 'Landing',
        attributeMetaData: pageContext.attributeMetaData,
      }}>
      <Layout
        location={location}
        meta={{
          title: meta_title || title,
          description: meta_description,
          keywords: meta_keywords,
          canonical: '/',
          robots: ['index', 'follow'],
        }}
        seo={{
          title: meta_title || title,
          description: meta_description,
          type: 'website',
          url: '/',
        }}
        content={{ heading: content_heading, html: content }}
        showBreadcrumbs={false}
        currentPageName={title}>
        <SubCategoryGrid
          images={subCategoryData}
          className="lg:container lg:mx-auto"
          categories={subCategoryData}
        />
      </Layout>
    </PageProvider>
  );
}

export const query = graphql`
  query LandingPage($rootCatId: Int!) {
    allMagentoCategory(filter: { parent_category_id: { eq: $rootCatId } }) {
      edges {
        node {
          magento_id
          canonical_url
          id
          name
          meta_title
          meta_keywords
          meta_description
          position
          image {
            large: childImageSharp {
              gatsbyImageData(
                breakpoints: [200]
                height: 305
                width: 508
                transformOptions: { fit: CONTAIN }
              )
            }
            small: childImageSharp {
              gatsbyImageData(
                breakpoints: [200]
                height: 195
                width: 325
                transformOptions: { fit: CONTAIN }
              )
            }
          }
        }
      }
    }

    sus {
      cmsPage(identifier: "home") {
        identifier
        meta_keywords
        meta_title
        meta_description
        title
        url_key
        content_heading
        content
      }
    }
  }
`;
